var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-toolbar-title',[_vm._v("Association des calques")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":""},on:{"click":function($event){return _vm.switchModal('modalLayer', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-1 pa-1"},[(!_vm.dialogLayer)?_c('div',{staticStyle:{"max-height":"400px","overflow-y":"auto"}},[_c('v-data-table',{staticClass:"mt-2 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"left","dense":"","colored-border":"","type":"info"}},[_vm._v("Versions")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":"green"},on:{"click":function($event){_vm.dialogLayer = true; _vm.lra_source_id=''}}},[_vm._v(" Nouveau"),_c('v-icon',[_vm._v("mdi-playlist-edit")])],1)],1)]},proxy:true},{key:"item.lra_custom_icon",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":""},on:{"click":function($event){_vm.iconDialog[item.lra_id] = !_vm.iconDialog[item.lra_id]}}},[_c('v-icon',[_vm._v("mdi-pencil-plus")]),_c('v-dialog',{attrs:{"light":"","hide-overlay":"","width":"500"},model:{value:(_vm.iconDialog[item.lra_id]),callback:function ($$v) {_vm.$set(_vm.iconDialog, item.lra_id, $$v)},expression:"iconDialog[item.lra_id]"}},[_c('div',{staticStyle:{"background-color":"white","z-index":"2555"}},[_c('v-textarea',{staticClass:"pa-5",attrs:{"label":"Icone","counter":"","maxlength":"65000"},model:{value:(item.lra_custom_icon),callback:function ($$v) {_vm.$set(item, "lra_custom_icon", $$v)},expression:"item.lra_custom_icon"}})],1)])],1)]}},{key:"item.lra_tem_id",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"item-text":"tem_label","item-value":"tem_id","items":_vm.getCacheTheme()},model:{value:(item.lra_tem_id),callback:function ($$v) {_vm.$set(item, "lra_tem_id", $$v)},expression:"item.lra_tem_id"}})]}},{key:"item.lra_obt_id",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"item-text":"obt_label","item-value":"obt_id","items":_vm.objectList[ 'tem' + item.lra_tem_id ]},model:{value:(item.lra_obt_id),callback:function ($$v) {_vm.$set(item, "lra_obt_id", $$v)},expression:"item.lra_obt_id"}})]}},{key:"item.lra_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"green"},on:{"click":function($event){return _vm.saveLayerAssoc(item)}}},[_vm._v(" mdi-content-save ")])]}}],null,true)},[(!_vm.loading.getLayer)?_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune donnée ")])],1):_vm._e(),(_vm.loading.getLayer)?_c('template',{slot:"no-data"},[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_vm._e()],2)],1):_vm._e(),(_vm.dialogLayer)?_c('v-layout',{staticClass:"pa-5",staticStyle:{"background-color":"white"},attrs:{"row":"","justify-center":""}},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Ajouter un calque","required":""},model:{value:(_vm.lra_source_id),callback:function ($$v) {_vm.lra_source_id=$$v},expression:"lra_source_id"}})],1),_c('v-card-actions',{staticClass:"text-lg-right"},[_c('v-btn',{attrs:{"color":"success","loading":_vm.saveProgress,"disabled":_vm.saveProgress},nativeOn:{"click":function($event){return _vm.postLayerAssoc()}}},[_vm._v("Enregistrer")]),_c('v-btn',{attrs:{"color":"primmary"},nativeOn:{"click":function($event){_vm.dialogLayer = false;}}},[_vm._v("Retour ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }