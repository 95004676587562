<template>
<v-card color="grey lighten-4" min-width="350px" flat >
    <v-toolbar color="primary" dark class="elevation-1" height="50px">
        <v-toolbar-title >Association des calques</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon color="" @click="switchModal('modalLayer', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-1 pa-1">
        <div v-if="!dialogLayer" style="max-height:400px; overflow-y: auto;">
            <v-data-table :headers="headers" :items="items" :items-per-page="10" class="mt-2 elevation-1" >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            <v-alert class="mb-0" border="left" dense colored-border type="info">Versions</v-alert>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    <v-btn outlined rounded small color="green" @click="dialogLayer = true; lra_source_id=''">
                        Nouveau<v-icon>mdi-playlist-edit</v-icon>
                    </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.lra_custom_icon`]="{ item }">
                <v-btn text icon color="" @click="iconDialog[item.lra_id] = !iconDialog[item.lra_id]" >
                    <v-icon >mdi-pencil-plus</v-icon>
                    <v-dialog light v-model="iconDialog[item.lra_id]" hide-overlay width="500">
                        <div style="background-color:white; z-index: 2555;">
                            <v-textarea class="pa-5" label="Icone" counter maxlength="65000" v-model="item.lra_custom_icon"></v-textarea>
                        </div>
                    </v-dialog>
                </v-btn>
                </template>
                <template v-slot:[`item.lra_tem_id`]="{ item }">
                    <v-autocomplete item-text="tem_label" item-value="tem_id"
                        v-model="item.lra_tem_id" :items="getCacheTheme()" ></v-autocomplete>
                </template>
                <template v-slot:[`item.lra_obt_id`]="{ item }">
                    <v-autocomplete item-text="obt_label" item-value="obt_id"
                        v-model="item.lra_obt_id" :items="objectList[ 'tem' + item.lra_tem_id ]" ></v-autocomplete>
                </template>
                <template v-slot:[`item.lra_sta_id`]="{ item }">
                    <v-icon  left color="green" @click="saveLayerAssoc(item)"> <!-- v-show="item.pre_lra_obt_id !== item.lra_obt_id" -->
                        mdi-content-save
                    </v-icon>
                </template>
                <template v-if="!loading.getLayer" slot="no-data">
                    <v-alert :value="true" color="error" icon="mdi-alert"> Aucune donnée </v-alert>
                </template>
                <template v-if="loading.getLayer" slot="no-data">
                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                </template>
            </v-data-table> 
        </div>
<!--    <div v-if="!dialogLayer" class="text-lg-right" style="background-color:#fff;">
    <v-divider></v-divider>
        <v-btn color="success" @click.native="saveLayerAssoc()">Enregistrer</v-btn>
        <v-btn color="blue darken-1" text @click.native="switchModal('modalLayer', 0)">Annuler</v-btn>
    </div>
-->
    <!-- Creation de Layer -->
        <v-layout v-if="dialogLayer" row justify-center class="pa-5" style="background-color:white;">
            <v-card>       
            <v-card-text>
                <v-text-field v-model="lra_source_id" label="Ajouter un calque" required></v-text-field>
            </v-card-text>
            <v-card-actions class="text-lg-right">
                <v-btn color="success" :loading="saveProgress" @click.native="postLayerAssoc()" :disabled="saveProgress">Enregistrer</v-btn>
                <v-btn color="primmary" @click.native="dialogLayer = false;">Retour </v-btn>
            </v-card-actions>            
            </v-card>
        </v-layout>
    </v-card-text>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'tab_layer',
    props: ['toast', 'objProject', 'prv_id', 'switchModal'],
    data: function () {
      return {
            saveProgress: false,         // Enregistrement en cours...
            loading: { getLayer: false },
            dialogLayer: false, lra_source_id: '',
            headers: [
                { text: 'Source', align: 'left', value: 'lra_source_id'},
                { text: 'Icone',  align: 'left', value: 'lra_custom_icon'},
                { text: 'Theme',  align: 'left', value: 'lra_tem_id'},
                { text: 'objet',  align: 'left', value: 'lra_obt_id'},
                { text: 'Statut', value: 'lra_sta_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            themeList : this.getCacheObjectType(),
            objectList : {},
            iconDialog: {}
      }
    },
    beforeMount: function(){
        let objectType = this.getCacheObjectType()
        objectType.forEach(obt => {
            if( this.objectList[ 'tem' + obt.obt_tem_id ] === undefined ){
                this.objectList[ 'tem' + obt.obt_tem_id ] = []
            }
            this.objectList[ 'tem' + obt.obt_tem_id ].push(obt)
        });
        this.objectList[ 'temnull' ]  = []
        this.objectList[ 'tem0' ]     = []
    },
    mounted: function (){
        this.getLayerAssoc()
    },
    methods: {
        getLayerAssoc(){
            this.loading.getLayer = true
            this.$http.get( '/layersassoc/?per_page=false&lra_prv_id=' + this.prv_id )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(item => {
                        item.pre_lra_tem_id = item.lra_tem_id
                        item.pre_lra_obt_id = item.lra_obt_id
                    })
                    response.data.data.forEach( (item) => {
                        this.$set(this.iconDialog, item.lra_id, false)
                    })

                    this.items = response.data.data
                }
                this.loading.getLayer = false
            })
        },
        saveLayerAssoc(item){
            this.$http.put( '/layersassoc/' + item.lra_id, item )
            .then( (response) => {
                this.items.forEach( elem => {
                    if( response.data.data && elem.lra_id == response.data.data.lra_id ){
                        elem.pre_lra_tem_id = response.data.data.lra_tem_id
                        elem.pre_lra_obt_id = response.data.data.lra_obt_id
                    }
                })
                this.toast({ color: 'success', text: 'Association enregistrée !' })
            })
        },
        postLayerAssoc(){
            this.$http.post( '/layersassoc/', { 
                lra_source_id: this.lra_source_id, lra_sta_id: 'GEN01', 
                lra_sta_id_type: 'LAY01', lra_prv_id: this.prv_id,
                lra_default_visibility: 1, lra_color: '#757575', 
            }).then( () => {
                this.dialogLayer = false
                this.lra_source_id = ''
                this.getLayerAssoc()
                this.toast({ color: 'success', text: 'Association enregistrée !' })
            })
        },
        addLayer(){
            this.$http.post( '/geojson/', { _method: 'POST', _path: 'geojson/' + this.objProject.pro_id + '/layeradd', lra_source_id: this.lra_source_id } )
            .then( () => {
                this.dialogLayer = false
                this.toast({ text: 'Calque enregistré !' })
                this.lra_source_id = ''
                this.getLayerAssoc()
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getCacheTheme: cacheGetters.getTheme,
        getCacheObjectType : cacheGetters.getObjectType,
    },
    components: { }
}
</script>