<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="elevation-1" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Utilisateurs</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn outlined rounded small color="green" @click="addUser(0)"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>        
        <template v-slot:[`item.pru_created_at`]="{ item }">
            <v-chip label outlined>{{ locDateFormat.toLocale( item.pru_created_at ) }}</v-chip>
        </template>
        <template v-slot:[`item.pru_deleted_at`]="{ item }">
            <v-chip label outlined color="red" v-if="(item.pru_deleted_at)">Inactif</v-chip>
            <v-chip label outlined color="green" v-else>Actif</v-chip>
        </template>
        <template v-slot:[`item.pru_usr_id`]="{ item }">
            <v-icon left @click="deleteUser(item.pru_usr_id)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getEvent )" color="error" icon="mdi-alert">
            Aucun utilisateur
        </v-alert>
        </template>
    </v-data-table>
    <v-dialog v-model="modalAddUser" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Ajout d'un utilisateur</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalAddUser = !modalAddUser"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <auto-multi :title="'Utilisateur'" ressource="users" :listObject.sync="autoComplete.pru_usr_id"
                    :listParent.sync="autoCompleteSource.usr" :fields="{ index: 'usr_id', text:'usr_firstName,usr_lastName', search:'usr_firstName,usr_lastName', multiSelect:false }">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2 mt-2" style="text-align:right;">
                <v-btn rounded color="success" @click.native="saveUser()" :disabled="autoComplete.pru_usr_id == null">
                    Enregistrer
                </v-btn>
            </v-flex>
            </v-layout>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'tab_version',
    props: ['toast', 'pro_id'],
    data: function () {
        return {
            locDateFormat: dateLocale,
            loading: { uploadBtn: false, rebuildBtn: false, userList: false, userTimeout: false },         // Enregistrement en cours...
            stateColor: {},
            pagination: {}, pagin: { total: 1, currentPage: 1, limit: 0, perPage: 20, totalPage: 1 },
            headers: [
            { text: 'Nom',       align: 'left',     value: 'usr_lastName'},
            { text: 'Prénom',    align: 'left',  value: 'usr_firstName'},
            { text: 'Téléphone', align: 'right', value: 'usr_phone',      sortable: false },
            { text: 'Ajouté', align: 'right', value: 'pru_created_at', sortable: false },
            { text: 'Statut', align: 'right', value: 'pru_deleted_at', sortable: false },
            { text: 'Action', align: 'right', value: 'pru_usr_id',     sortable: false },
            ],
            items: [],
            enableAdd: true,
            addItem: {
                pru_usr_id: 0, pru_pro_id: this.pro_id, pru_sta_id: 'GEN01', pru_sta_init: 'GEN01', pru_background: 0,
                pru_date_modif: '', search: '',
                _method: 'POST', _path: 'projectver'
            },
            modalLayer : false,
            usr_tab: [],
            prv_id: 0,
            searchUser: null,
            modalAddUser : false,
            autoComplete: {
                pru_usr_id: null,
            },
            autoCompleteSource:{
                usr: [],
            },
        }
    },
    beforeMount: function (){ },
    mounted: function (){
        this.getUsers()
    },
    watch: {
        searchUser (val) {
            window.clearTimeout(this.loading.userTimeout)
            this.loading.userTimeout = setTimeout( () => {
                val && val !== this.addItem.search && this.getSearchUser(val)
            }, 600)
        }
    },
    methods: {
        getUsers(){
            this.$http.get( '/projectusers/?per_page=false&extended=withuser&pru_pro_id=' + this.pro_id )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
            })
        },
        addUser() {
            this.enableAdd    = false
            this.modalAddUser = true
            //this.items.push({ pru_usr_id: 0, pru_sta_id: 'GEN01' })
        },
        getSearchUser( user ){
            this.loading.userList = true
            this.$http.get( '/users/?usr_middleName=%' + user + '%' )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data ) === '[object Array]' ) {
                    this.usr_tab = response.data
                }
                this.loading.userList = false
            })
        },
        saveUser(){
            this.$http.post( '/projectusers/', { pru_usr_id: this.autoComplete.pru_usr_id } ).then( () => {
                this.modalAddUser = false
                this.enableAdd    = true
                this.getUsers()
            })
        },
        deleteUser( usr_id ){
            this.$http.delete( '/projectusers/' + usr_id + '/' +  this.pro_id ).then( () => {
                this.getUsers()
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'auto-multi': autoCompMulti,
    }
}
</script>