<template>
<v-card flat style="overflow-x: hidden;">
    <v-tabs v-model="activeTab" grow light show-arrows="mobile">
        <v-tabs-slider color="green"></v-tabs-slider>
        <v-tab class="pl-1 pr-1"><v-icon class="mr-1">mdi-calendar</v-icon><span class="hidden-sm-and-down">Evenement</span></v-tab>
        <v-tab class="pl-1 pr-1" :disabled="!eveId"><v-icon class="mr-1" :disabled="true">mdi-file</v-icon><span class="hidden-sm-and-down">Fichiers</span></v-tab>
    </v-tabs>
    <v-card v-if="activeTab === 0" flat>
        <v-card-text class="ma-3">
        <v-row>
            <v-col cols="6" md="6">
                <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <DatePicker label="Date début" :objectForm="objEvent" fieldName='eve_date_start'></DatePicker>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <DatePicker label="Date fin" :objectForm="objEvent" fieldName='eve_date_end'></DatePicker>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field label="Objet" prepend-icon="mdi-script-text-outline" placeholder="Titre" v-model="objEvent.eve_title"></v-text-field>
                    </v-col>
                </v-row>    
            </v-col>
            <v-col cols="6" md="6" class="pt-0 pb-0 pl-1">
                <v-col cols="12" md="12" align="center" justify="center" class="pt-0 pb-0">
                    <v-icon v-show="(!uploadedImage && !resizeImage && !objEvent.eve_doc_id_image)" size="10em" @click="$refs.inputUpload.click()">mdi-camera</v-icon>
                    <img ref="imgSrc" :src="uploadedImageSrc" v-show="false" />
                    <v-img ref="resizedImage" max-width="100%" max-height="20em" style="cursor:pointer;"
                            v-show="(uploadedImage || objEvent.eve_doc_id_image)" :src="resizedImage" aspect-ratio="1" @click="$refs.inputUpload.click()" class="elevation-3 rounded-lg">
                    </v-img>
                    <v-progress-circular v-if="resizeImage" :size="100" :width="5" color="primary" indeterminate></v-progress-circular>
                    <input ref="inputUpload" v-show="false" accept="image/*" type="file" @change="handleImageImport()" /> 
                </v-col>
            </v-col>
        </v-row>
        <v-row class="pr-6">
            <v-col cols="12" md="12">
                <v-select :items="types" item-text="typ_name" item-value="typ_id" v-model="objEvent.eve_typ_id" 
                        label="Statut" single-line prepend-icon="mdi-format-list-bulleted-type">   
                </v-select>
            </v-col>
            <v-col cols="6" md="6" v-if="objEvent.eve_typ_id.substring(0,3) == 'MEV'">
                <v-text-field label="Numero devis" prepend-icon="mdi-currency-eur" v-model="objEvent.eve_custom_fields.eve_quotation_id"></v-text-field>
            </v-col>
            <v-col cols="6" md="6" v-if="objEvent.eve_typ_id.substring(0,3) == 'MEV'">
                <v-text-field label="Montant" prepend-icon="mdi-currency-eur" v-model="objEvent.eve_cost_value"></v-text-field>
            </v-col>
            <!-- <v-col cols="3" md="3">
                <v-switch v-model="objEvent.eve_delegation" inset label="Sous traité"></v-switch>
            </v-col> -->
            <v-col cols="12" md="12">
                <v-textarea rows="2" auto-grow prepend-icon="mdi-comment" label="Description" v-model="objEvent.eve_description"></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
                <v-subheader class="pl-0">Résolution / avancement</v-subheader>
                <v-slider class="mx-3" v-model="objEvent.eve_resolve_percent" :step="10" thumb-label="always">
                </v-slider>
            </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions style="background-color:#E5E5E5;">
            <v-btn rounded class="ma-0" color="error" @click.native="objEvent.eve_sta_id = 'GEN03'; deleteEvent()" :disabled="saveProgress">
                <v-icon left>mdi-record</v-icon>Supprimer
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded text color="grey" @click="switchModal('modalEvent', 0)">
                <v-icon left dark>mdi-close</v-icon>Annuler
            </v-btn>
            <v-btn v-if="objEvent.eve_sta_id == 'GEN01'" rounded color="orange" :loading="saveProgress" 
                @click.native="saveEvent('GEN03')" :disabled="saveProgress" class="ml-3">
                <v-icon left dark>mdi-check</v-icon>Terminer
            </v-btn>
            <v-btn v-if="objEvent.eve_sta_id == 'TSK02'" rounded color="orange" :loading="saveProgress" 
                @click.native="saveEvent('TSK03')" :disabled="saveProgress" class="ml-3">
                <v-icon left dark>mdi-check</v-icon>Accepter
            </v-btn>
            <v-btn rounded color="success" :loading="saveProgress" @click.native="saveEvent()" :disabled="saveProgress" class="ml-3">
                <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
    <DocumentTab v-if="activeTab === 1 && showTab == true" :doc_xxx_id="eveId" :table_id="'eve'" 
        :routeState="{ siteView:false, siteEdit:true, view:false, edit:true, add:true, state: 'modifier' }" style="min-height: 474px;">
    </DocumentTab>
    </v-card>
</template>

<script>
import { eveObj } from '@/mixins/eve_obj.js'
import { docObj } from '@/mixins/doc_obj.js'
import { cacheGetters } from "@/store/cache.module"
import DatePicker from '@/components/ui/DatePicker'
import DocumentTab  from '@/components/documents/DocumentList.vue'
import Pica from "pica"

export default {
    name: 'eventedit',
    components: { DocumentTab, DatePicker },
    props: ['xxxId', 'eveId', 'eveTable', 'eveType', 'switchModal', 'cooridnates'],
    mixins: [ eveObj, docObj ],
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            states : [],
            types : [],
            dateEve: { start: false, end: false },
            optionMenu: false,
            eventType: {
                'act' : [ 'ALT' ],
                'mev' : [ 'MEV' ],
            },
            activeTab: 0, showTab: true,
            uploadedImage: false,
            resizeImage: false,
            resizedImage: '',
            uploadedImageSrc: ''
        }
    },
    //beforeMount: function(){ },
    mounted:function (){
        if ( this.eveType ){
            this.types = this.types.concat( cacheGetters.getTypes(this.eveType) )
        } else if( this.eventType[this.eveTable] !== undefined ){
            this.eventType[this.eveTable].forEach(element => {
                this.types = this.types.concat( cacheGetters.getTypes(element) )
            });
        } else {
            this.types = this.types.concat( cacheGetters.getTypes('MEV') )
        }
        if( this.eveId !== undefined && this.eveId > 0 ){
            this.objEvent.eve_id = this.eveId
            this.getEventShow().then( () => {
                if( this.objEvent.eve_doc_id_image > 0 ){
                    this.getImage(this.objEvent.eve_doc_id_image)
                }
            })
        } else {
            this.cleanObjEvent()
            this.objEvent.eve_id         = 0
            this.objEvent.eve_typ_id     = this.types[0].typ_id
            this.objEvent.eve_table_id   = this.eveTable
            this.objEvent.eve_xxx_id     = this.xxxId
            this.objEvent.eve_date_start = this.$date().format('YYYY-MM-DD')
        }
        if( this.cooridnates && this.cooridnates.lat && this.cooridnates.lng ){
            this.objEvent.eve_lat = this.cooridnates.lat
            this.objEvent.eve_lng = this.cooridnates.lng
        }
    },
    methods: {
        saveEvent(state){
            this.saveProgress = true
            if( this.objEvent.eve_id !== undefined && this.objEvent.eve_id > 0 ){
                this.objEvent.eve_sta_id = ( state ) ? state : this.objEvent.eve_sta_id
                this.putEventUpdate().then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.switchModal('modalEvent', 0, true)
                    if( this.uploadedImage ){
                        this.objDocument.doc_xxx_id   = this.objEvent.eve_id
                        this.objDocument.doc_table_id = 'eve'
                        this.objDocument.doc_typ_id   = 'DOC03'
                        this.saveDocument().then( () => {
                            this.attachDocument(this.objDocument.doc_id)
                        })
                    }
                    this.saveProgress = false
                })
            } else {
                this.postEventStore().then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.switchModal('modalEvent', 0, true)
                    if( this.uploadedImage ){
                        this.objDocument.doc_xxx_id   = this.objEvent.eve_id
                        this.objDocument.doc_table_id = 'eve'
                        this.objDocument.doc_typ_id   = 'DOC03'
                        this.saveDocument().then( () => {
                            this.attachDocument(this.objDocument.doc_id)
                        })
                    }
                    this.saveProgress = false
                })
            }
        },
        deleteEvent(){
            this.$root.$confirm('Supprimer cet évènement', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && this.objEvent.eve_id > 0 ){
                    this.deleteObjEvent().then( () => {
                        this.switchModal('modalEvent', 0, true)
                    })
                }
            })
                
        },
        getImage(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                } else {
                    var reader = new FileReader();
                    reader.readAsDataURL(response.data); 
                    reader.onloadend = () => {
                        var base64data   = reader.result;                
                        this.resizedImage = base64data;
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        handleImageImport(){
            this.resizeImage      = true
            const pica            = Pica();
            var files             = this.$refs.inputUpload.files
            this.uploadedImageSrc = window.URL.createObjectURL(files[0])

            setTimeout( () => {
                let dim = this.calculateAspectRatioFit(this.$refs.imgSrc.width, this.$refs.imgSrc.height, 720, 1080)

                const resizedCanvas  = document.createElement("canvas")
                resizedCanvas.height = dim.height
                resizedCanvas.width  = dim.width

                pica.resize(this.$refs.imgSrc, resizedCanvas).then(result => {
                    this.resizedImage  = result.toDataURL()
                    pica.toBlob(result, 'image/jpeg', 0.85).then( (blob) => {
                        blob.name = files[0].name
                        this.objDocument.doc_name = files[0].name
                        this.objDocument.obj_file = blob
                    })
                    // Update states
                    this.resizeImage   = false
                    this.uploadedImage = true
                })
                .catch(error => {
                    console.log(error);
                });
            }, 500)

        },
        calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return { width: srcWidth * ratio, height: srcHeight * ratio };
        },
        saveDocument(){
            return new Promise( (resolve) => {
                if( this.objDocument.doc_id !== undefined && this.objDocument.doc_id > 0 && !this.objDocument.obj_file ){
                    this.putDocumentUpdate().then( () => {
                        this.$emit('update:docId', this.objDocument.doc_id)
                        resolve('resolved')
                    })
                } else {
                    this.postDocumentStore().then( () => {
                        this.$emit('update:docId', this.objDocument.doc_id)
                        resolve('resolved')
                    })
                }
            })
        },
        attachDocument(doc_id){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/events/' + this.objEvent.eve_id + '/picture', { eve_doc_id_image: doc_id } ).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Image attribuée' })
                    this.$emit('linkDoc', doc_id)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
    }
}
</script>